<template>
  <!-- <v-file-input @change="fileChanged" show-size label="Файл" v-model="file"></v-file-input> -->
  <ElUpload drag :before-upload="handleBefore" :action="actionURL" :on-error="handleError" :on-success="handleSuccess" :on-exceed="handleExceed" :disabled="false" :limit="10" :multiple="false" :show-file-list="false" class="upld-wrpr"
  accept="image/png, image/jpeg, image/jpg, image/bmp, application/pdf">
    <slot name="trigger" />
  </ElUpload>
</template>

<style>
.upld-wrpr .el-upload {
  width: 100% !important;
}
.upld-wrpr .el-upload-dragger {
  width: 100% !important;
  height: 100% !important;
}
</style>

<script>
//import { uploader } from "@/api/media";
import { getToken } from "@/libs/util";
import baseURL from "../../config/url";
// import Axios from "axios";
//import Cookies from "js-cookie";

//import "element-ui/lib/theme-chalk/index.css";

import { ElUpload } from "element-plus";

import 'element-plus/lib/theme-chalk/index.css';

import { hasOneOf } from "@/libs/tools";

export default {
  name: "simpleUploader",
  components: {
    ElUpload
  },
  props: {
    folderId: {
      type: Number,
      default: () => null
    },
    boxId: {
      type: Number,
      default: () => 0
    },
    objectTypeId: {
      type: Number,
      default: () => 0
    },
    mode: {
      type: Number,
      default: () => 0
    },
    objectId: {
      type: String,
      default: () => "0"
    }
  },
  data() {
    return {
      actionURL: "",
      file: null,
      fileExceedSize: 102400,
      fileExceedMsg:
        "Файл байршуулах хэмжээ их байна. Хуулах хамгийн их хэмжээ: 100мб байна."
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;
      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    hasPermission(perrArr) {
        return false;
      //return hasOneOf(perrArr, this.$store.state.user.access);
    },
    handleExceed() {
      //this.$Message.warning({content: this.fileExceedMsg, duration: 15});
      this.toggleBodyClass("removeClass", "dynamic-locker");
      this.uploadStart = false;
    //   this.$bvToast.toast("1 удаа 10-с илүү файл хуулах боломжгүй", {
    //     title: "Сануулга",
    //     autoHideDelay: 10000,
    //     appendToast: true,
    //     variant: "success",
    //     toaster: "b-toaster-bottom-left"
    //   });
    },
    handleBefore(file) {
      if (this.folderId == 0) {
        //this.$Message.warning("Файл байршуулах хавтас сонгоно уу");
        // this.$bvToast.toast("Файл байршуулах хавтас сонгоно уу", {
        //   title: "Сануулга",
        //   autoHideDelay: 10000,
        //   appendToast: true,
        //   variant: "warning",
        //   toaster: "b-toaster-bottom-left"
        // });
        return false;
      }else {
        if ((file.size /1024) > this.fileExceedSize) {
        //   this.$bvToast.toast(this.fileExceedMsg, {
        //     title: "Сануулга",
        //     autoHideDelay: 10000,
        //     appendToast: true,
        //     variant: "warning",
        //     toaster: "b-toaster-bottom-left"
        //   });
          return false;
        }

        this.toggleBodyClass("addClass", "dynamic-locker");
        this.uploadStart = true;
      }
    },
    handleError(e) {
      //console.log(e);
      this.toggleBodyClass("removeClass", "dynamic-locker");
      this.$emit("on-error", e);
    },
    handleSuccess(e) {
      console.log('handleSuccess', e)
      this.toggleBodyClass("removeClass", "dynamic-locker");
      this.uploadStart = false;
      if (e.code == 200) {
        //console.log(e);
        //this.$emit("inpit", { response: e });
        //this.$emit("on-success", { response: e });
        this.$emit("inpit", e.data);
        this.$emit("on-success", e.data);

        // this.$bvToast.toast("Файлыг серверт амжилттай хууллаа", {
        //   title: "Амжилттай",
        //   autoHideDelay: 2000,
        //   appendToast: true,
        //   variant: "success",
        //   toaster: "b-toaster-bottom-left"
        // });
        // this.$Notice.success({
        //   title: "Амжилттай",
        //   desc: "Файлыг серверт амжилттай хууллаа."
        // });
      } else {
        // this.$bvToast.toast(e.msg, {
        //   title: "Сануулга",
        //   autoHideDelay: 5000,
        //   appendToast: true,
        //   variant: "error",
        //   toaster: "b-toaster-bottom-left"
        // });
        // this.$Notice.warning({
        //   title: "Сануулга",
        //   desc: e.msg
        // });
      }
    },

    init() {
    //   if (this.hasPermission(["learningstudent"])) {
    //     this.fileExceedSize = this.$store.state.app.activeGroup.fileUploadSize.student.size;
    //     this.fileExceedMsg = this.$store.state.app.activeGroup.fileUploadSize.student.msg;
    //   } else {
    //     this.fileExceedSize = this.$store.state.app.activeGroup.fileUploadSize.default.size;
    //     this.fileExceedMsg = this.$store.state.app.activeGroup.fileUploadSize.default.msg;
    //   }

      this.fileExceedSize= 5120;
      this.fileExceedMsg='Файл байршуулах хэмжээ их байна. Хуулах хамгийн их хэмжээ: 5мб байна';

      this.actionURL =
        baseURL +
        "core/uploader?boxId=" +
        this.boxId +
        "&folderId=-999" +
        "&objectTypeId=6" +
        "&objectId=0" +
        "&mode=0" +
        "&token=" +
        getToken();
    }
  }
};
</script>
